import {Author, AuthorIdentity} from '../types/author.types'
import {AuthorFieldsFragment, AuthorIdentityFieldsFragment} from '../../../../__generated__/datocms.types'
import {parseAsset2, parseAssetWithSize} from './asset.serde'
import {parseCallToAction} from './call-to-action.serde'

export function parseAuthorIdentity(f: AuthorIdentityFieldsFragment): AuthorIdentity {
  return {
    name: f.name!,
    portrait: f.portrait ? parseAsset2(f.portrait) : undefined,
  }
}

export function parseAuthorCollection(collection: AuthorFieldsFragment[] | undefined): Author[] {
  return (
    collection?.filter(item => item.__typename === 'AuthorModelRecord' && item.name).map(item => parseAuthor(item)) ??
    []
  )
}

function parseAuthor(f: AuthorFieldsFragment): Author {
  return {
    ...parseAuthorIdentity(f),
    description: f.description,
    partnerLogo: f.partnerLogo ? parseAssetWithSize(f.partnerLogo) : undefined,
    partnerCta: f.partnerLogoCta ? parseCallToAction(f.partnerLogoCta) : undefined,
    cta: f.cta ? parseCallToAction(f.cta) : undefined,
  }
}
